<template>
    <div >
        <el-row style="text-align: right;">
            <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
                <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
            </el-select>
        </el-row>
        <el-row>
            <el-tabs id="tabs" v-model="activeName" tab-position="top" @tab-click="handleTabClick" style="z-index: 100;">
                <el-tab-pane label="APP用戶" name="APP_USER">
                </el-tab-pane>
                <el-tab-pane label="RFID卡片" name="RFID">
                
                </el-tab-pane>
            </el-tabs>
            <TokenConfigSetting ref="tokenConfigSetting"  />
        </el-row>
    </div>
</template>

<script>
  import TokenConfigSetting from '@/views/ocpi/tokenConfigSetting'
  import { mixins } from '@/views/common/mixins.js'
  import {refreshToken,stopRefreshToken} from "@/utils/auth";
  import {apiUrl} from "@/utils/main";
  export default {
    name: 'Service',
    components: {
        TokenConfigSetting
    },
    mixins: [mixins],
    data() {
      return {
        activeName: 'APP_USER',
        total: 0,
        list: [],
        listLoading: false,
        listQuery: {
          type: '',
          enabled: true,
          serviceId: undefined,
          startdate: undefined,
          enddate: undefined,
          name: undefined,
          item: undefined
        },
        defaultSort: { prop: 'timestamp', order: 'descending' },
        serviceList: [],
        reportDate: this.formatCurrentDateTime()
      };
    },
    watch: {
        'activeName': function (value) {
            console.log("watch activeName value",value);
            this.$refs.tokenConfigSetting.loadConfig(value)
        },
        'listQuery.serviceId': function (value) {
            console.log("watch listQuery.serviceId value",value);
            this.$refs.tokenConfigSetting.loadConfig(this.activeName, value)
        }
    },
    async created() {
      this.activeName = 'APP_USER'
      this.getServiceOptions()
      //console.log('@currentAccount=', this.$store.state.currentAccount)
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.dialogWidth = this.setDialogWidth()
        })()
      }
    },
    methods: {
      
      getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            console.log("getServiceOptions");
            this.serviceList = res.data
            if(this.$store.state.currentAccount !== undefined) {
                //console.log(this.$store.state.currentAccount);
                this.listQuery.serviceId = this.$store.state.currentAccount.serviceInfo.id
            } 
        })
      },
      handleTabClick(tab, event) {
        //console.log(tab, event);
       
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  
  </style>
  
