<template>
    <div >
        <el-row :gutter="20" class="profile">
            <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" style="text-align: center; ">
                <span style="font-weight: bold; font-size: 1.2em;">{{ activeDisplayName }} 通行證設定</span>
            </el-col>
        </el-row>
        <div v-loading="isLoading">
        <el-row :gutter="20" class="profile" v-if="isDefault">
            <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22" style="text-align: center; " >
                <span style="color: #ff0000; font-size: 1em;">尚未客製化設定，顯示系統預設資訊</span>
            </el-col>
        </el-row>
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" label-width="43%" label-position="right" size="small" style="margin-top: 20px;">
            <el-form-item prop="type" label="類型" style="min-height: 45px;" >
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10" style="text-align: left;">
                    <el-input id="type" name="type" type="text" size="small" v-model="updateQuery.type" class="filter-item custom-width" disabled style="background-color: #fff; "/>
                </el-col>
            </el-form-item>
            <el-form-item prop="whitelist" label="認證方式" style="min-height: 45px;" >
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10" style="text-align: left;">
                    <el-select id="whitelist" name="whitelist"  v-model="updateQuery.whitelist" placeholder="OCPI 通行證有效性" size="small" style="width: 360px;" >
                        <el-option label="白名單認證(ALWAYS)" title="通行證始終必須列入白名單，不可能/不允許即時授權。 CPO 應始終允許此通行證的任何使用。" value="ALWAYS" />
                        <el-option label="允許使用白名單認證或即時授權(ALLOWED)" title="允許將通行證列入白名單，也允許即時授權。 CPO 可以選擇使用哪個版本的授權。" value="ALLOWED" />
                        <el-option label="即時授權，但允許離線時白名單認證(ALLOWED_OFFLINE)" title="一般情況下應採用即時授權。但當CPO無法得到eMSP的回應時（CPO與eMSP之間的通訊離線），CPO應允許使用該通行證。" value="ALLOWED_OFFLINE" />
                        <el-option label="即時授權(NEVER)" title="禁止白名單，只允許即時授權。 CPO 應始終向 eMSP 發送對該通行證的任何使用的即時授權。" value="NEVER" />
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item prop="issuer" label="發行單位" style="min-height: 45px;" >
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10" style="text-align: left;">
                    <el-input id="issuer" namd="issuer" type="text" size="small" maxlength="64" v-model="updateQuery.issuer" class="filter-item custom-width" clearable/>
                </el-col>
            </el-form-item>
            <el-form-item prop="language" label="語系碼(ISO 639-1)" style="min-height: 45px;" >
                <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10" style="text-align: left;">
                    <el-input id="language" namd="language" type="text" size="small" maxlength="2" v-model="updateQuery.language" class="filter-item custom-width" clearable/>
                </el-col>
            </el-form-item>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"  @click="confirmUpdateData()" :disabled="saveDisabled">{{$t('Save')}}</el-button>
        </div>
    </div>
</template>

<script>

import { mixins } from '@/views/common/mixins.js';
export default {
    name: 'TokenConfigSetting',
    mixins: [mixins],
    data() {
        return {
            active: '',
            activeDisplayName: '',
            isLoading: true,
            listQuery: {                
                serviceId: undefined
            },
           
            updateQuery: {
                type: undefined,
                whitelist: 'ALLOWED',
                issuer: undefined,
                language: 'zh',
                serviceId: undefined
            },
            isDefault: false,
            saveDisabled: true,
            rules: {
                type: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                issuer: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                language: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }]
            },
            isFetching: false,
        };
    },
    watch: {
        updateQuery: {
            handler(oldVal, newVal) {
                if (!this.isFetching) {
                    console.log("watch updateQuery.whitelist:" + oldVal + "  "+ newVal )
                    if(newVal != this.updateQuery.whitelist){
                        
                        this.saveDisabled = false
                    }
                }
            },
            deep: true
        }
    },
    
    created() {
       
    },
    methods: {
      tokenTypeDisplay(type) {
        if(type == 'APP_USER') {
            return 'APP用戶'
        } else if(type == 'RFID') {
            return 'RFID卡片'
        } else {
            return type;
        }
      },
      loadConfig(type, serviceId) {
        console.log("loadConfig: " + type)
        this.isLoading = true
        this.isFetching = true;
        this.type= type
        this.active = type
        
        this.saveDisabled = true
        this.activeDisplayName = this.tokenTypeDisplay(this.active)
        
        if(type !== undefined && type.length > 0) {
            if(serviceId !== undefined) this.listQuery.serviceId = serviceId
            this.axios.get('/api/v1/ocpi/config/token/'+type, { params: this.listQuery }).then(res => {
                console.log(res.data)
                
                this.updateQuery.type = res.data.type
                this.updateQuery.whitelist = res.data.whitelist
                this.updateQuery.issuer = res.data.issuer
                this.updateQuery.language = res.data.language
                this.updateQuery.serviceId = res.data.serviceId
                
                this.isDefault = res.data.default
                console.log("isDefault:" + this.isDefault)
                this.isLoading = false
                this.saveDisabled = true
            }).finally(() => this.isFetching = false)
        }
      },
      confirmUpdateData(){
        this.$confirm(this.$t('Hint'), {
                message: '是否要更新通行證設定 ? 更新會套用於已產生的通行證資料，請通知合作充電營運商同步更新通行證資料',
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText:  this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                this.updateData()
            }).catch(() => {
                console.log('cancel update token config')
            });
      },
      updateData(){
        this.$refs['dataForm'].validate((valid) => {
            if (valid) {
                this.axios.put('/api/v1/ocpi/config/token/'+this.active, this.updateQuery).then(res => {
                    this.$message({
                        showClose: true,
                        message: this.$t('SuccessEdited')+', 共計更新 '+ res.data.countUpdated +'筆通行證',
                        type: 'success'
                    })
                    this.saveDisabled = true               
                }).catch(err => {
                    console.log('err:' + err)
                    this.$message({
                        message: this.$t('FailEdited'),
                        type: 'error'
                    })
                })
            }
        });
      }
    }
}
</script>
<style>
.el-input.is-disabled .el-input__inner {
    color: #606266;
    background-color: #fff;
}
.profile{
    padding: 10px;
}
</style>